import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Logo from "../assets/icons/olinfull.png";

export default function Seo({
  title,
  description,
  metaKeyword,
  name,
  canonLink,
  schema,
  tags,
  cat,
}) {
  useEffect(() => {
    if (tags || cat) {
      window.dataLayer.push({
        PostTags: tags ? tags : [],
        Categories: cat ? cat : [],
      });
    }
  }, [tags, cat]);

  return (
    <Helmet prioritizeSeoTags>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta
        name="description"
        content={description}
        data-react-helmet="true"
        data-rh="true"
      />
      <meta name="keywords" content={metaKeyword ? metaKeyword : ""} />
      {/* Schema markup */}
      <script type="application/ld+json">{schema ? schema : null}</script>
      {/* Facebook tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonLink} />
      <meta property="og:image" content={Logo} />
      <meta property="og:type" content="website" />
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:image" content={Logo} />
      {/* Canonical tags */}
      <link
        rel="canonical"
        href={canonLink}
        data-react-helmet="true"
        data-rh="true"
      />
    </Helmet>
  );
}
