import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { getArticleDetail, getArticleList } from "../../services/articleAPI";
import Seo from "../Seo";
import ArticleCard from "./ArticleCard";

const DetailArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const getTitleSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const {
    mutate: mutateDetailArticle,
    data: dataArticle,
    isLoading,
  } = useMutation({
    mutationFn: getArticleDetail,
  });

  const { mutate: mutateArticleList, data: dataArticleList } = useMutation({
    mutationFn: getArticleList,
  });

  useEffect(() => {
    const bodyMobile = document.getElementById("article-body");
    const body = document.getElementById("article-body-desktop");

    if (body !== null && dataArticle !== null) {
      bodyMobile.innerHTML += dataArticle.news.description;
      body.innerHTML += dataArticle.news.description;
    }
  }, [dataArticle]);

  useEffect(() => {
    mutateArticleList();
    mutateDetailArticle(id);
  }, [id]);

  return (
    <div>
      <style>
        {`h1, h2, h3, h4, h5, h6 {
               font-weight: revert;
               font-size: revert;
            }`}
      </style>

      <div>
        <Navbar />
      </div>
      {dataArticle ? (
        <div className="">
          <Seo
            title={dataArticle.news.title}
            description={dataArticle.news.meta}
            name={"OLIN"}
            canonLink={`https://olin.co.id/blog/${dataArticle.news.slug}`}
            metaKeyword={dataArticle.news.keyword.join(", ")}
            schema={JSON.stringify({
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              author: {
                "@type": "Person",
                name: dataArticle.news.author,
              },
              datePublished: dataArticle.news.created_at,
              dateModified: dataArticle.news.updated_at,
              name: dataArticle.news.title,
              articleBody: dataArticle.news.description,
              image: dataArticle.news.media,
              url: `https://olin.co.id/blog/${dataArticle.news.slug}`,
              // PostTags: dataArticle.news.tag, // TODO: move to tags
              // Categories: dataArticle.news.category_name,
              inLanguage: "id-ID",
            })}
            tags={dataArticle.news.tag}
            cat={dataArticle.news.category_name}
          />
          <div className="mobile flex flex-col md:flex-row block md:hidden">
            <div className="w-full">
              <img
                src={dataArticle.news.media}
                className="w-full object-contain w-full"
                alt={dataArticle.news.alt_banner}
              />
            </div>

            <div className="p-3">
              <p className="font-bold text-2xl p-3">{dataArticle.news.title}</p>
              <div className="flex gap-3 mb-4 p-3 flex-wrap">
                {dataArticle.news.category_name.map((cat) => {
                  return (
                    <p className="px-3 rounded-2xl bg-gray-300 p-2 ">{cat}</p>
                  );
                })}
              </div>
              <div id="article-body" className="!h-fit"></div>

              <div>
                <hr className="border-1 border-slate-200 rounded-md my-7" />
                <p className="font-bold text-left p-3">Artikel Lainnya</p>
                {dataArticle &&
                dataArticle.related &&
                dataArticle.related.length > 0 ? (
                  dataArticle.related.map((item) => {
                    return (
                      <div className="w-full">
                        <ArticleCard
                          categoryArr={item.category_name}
                          slug={item.slug}
                          title={item.title}
                          description={item.description}
                          media={null}
                          hasImage={null}
                          type={"small"}
                        />
                      </div>
                    );
                  })
                ) : (
                  <p className="my-4 text-gray-300">
                    Tidak ada artikel terkait
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="desktop flex flex-col hidden md:flex">
            <div className="flex flex-col md:flex-row pr-10">
              <div className="w-[100%] md:w-[70%]">
                <div className="w-full">
                  <img
                    src={dataArticle.news.media}
                    className="h-full w-full object-contain w-full"
                    alt={dataArticle.news.alt_banner}
                  />
                </div>
                <div className="w-full p-5 ml-10">
                  {dataArticle && !isLoading ? (
                    <div className="mt-5 mb-2 text-blue-500 flex items-center">
                      <p
                        className="cursor-pointer hover:font-bold"
                        onClick={() => navigate("/blog")}
                      >
                        Blog
                      </p>
                      <p className="mx-4">/</p>
                      <p
                        className="cursor-pointer hover:font-bold"
                        onClick={() =>
                          navigate(
                            `/blog/${dataArticle.news.category[0]}/${dataArticle.news.category_name[0]}`
                          )
                        }
                      >
                        {dataArticle.news.category_name[0]}
                      </p>
                      <p className="mx-4">/</p>
                      <p className="cursor-default">{dataArticle.news.title}</p>
                    </div>
                  ) : null}
                  <div className="text-[20px]">
                    <h1 className="font-bold text-[30px] mb-2">
                      {dataArticle.news.title}
                    </h1>
                    <div className="flex flex-row gap-3 flex-wrap text-[14px] mb-4">
                      {dataArticle.news.category_name.map((cat) => {
                        return (
                          <p className="px-3 rounded-2xl bg-gray-300 p-2">
                            {cat}
                          </p>
                        );
                      })}
                    </div>
                    <div
                      id="article-body-desktop"
                      className="ql-editor"
                      style={{
                        margin: 0,
                        padding: 0,
                        paddingRight: 24,
                        fontSize: 16,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="w-[30%] hidden md:block mt-5 p-5">
                <div className="bg-slate-100 rounded-md">
                  <div className="flex flex-row items-center justify-center gap-3 p-4">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100087468924959"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <BsFacebook className="w-full h-full text-3xl text-[#3b5998] hover:text-slate-700" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/olin_indonesia/"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <BsInstagram className="w-full h-full text-3xl text-[#E1306C] hover:text-slate-700" />
                    </a>

                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UC_qBcIbgRIYCR0aIPDD7_Bw"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <BsYoutube className="w-full h-full text-3xl text-[#FF0000] hover:text-gray-700" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/olin-all-in-one-pharmacy-management-system/mycompany/"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <FaLinkedinIn className="w-full h-full text-3xl text-[#0072b1] hover:text-gray-700" />
                    </a>
                  </div>
                </div>
                <div className="flex flex-col mt-5">
                  <p className="font-bold text-2xl">Artikel Lainnya</p>
                  <hr className="text-black border-1 border-slate-200 rounded-md" />
                  {dataArticle &&
                  dataArticle.related &&
                  dataArticle.related.length > 0 ? (
                    dataArticle.related.map((item) => {
                      return (
                        <div className="w-full">
                          <ArticleCard
                            categoryArr={item.category_name}
                            slug={item.slug}
                            title={item.title}
                            description={item.description}
                            media={null}
                            hasImage={null}
                            type={"small"}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <p className="my-4 text-gray-300">
                      Tidak ada artikel terkait
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="mt-12">
        <Footer />
      </div>
    </div>
  );
};

export default DetailArticle;
