import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import { getArticleList } from "../../services/articleAPI";
import ArticleCard from "./ArticleCard";

const Articles = () => {
  const { categoryId, categoryName } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [isAll, setIsAll] = useState(false);

  const getTitleSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const stripHtml = (text) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = text;
    return tmp.textContent || tmp.innerText || "";
  };

  const { mutate: mutateArticleList, data: dataArticleList } = useMutation({
    mutationFn: getArticleList,
    onError: (err) => {
      setErrorMessage(err.response.data.message.id);
    },
  });

  useEffect(() => {
    mutateArticleList(categoryId ? categoryId : 0);
  }, []);

  return (
    <div>
      <div className="bg-[#24337A]">
        <p className="pb-10 text-[#24337A]">{"..."}</p>
      </div>

      {categoryId ? (
        <p className="px-10 mt-10 text-[24px] font-bold">
          Artikel {categoryName}
        </p>
      ) : null}

      <div className="flex flex-col hidden md:flex">
        <div className="flex flex-col md:flex-row px-10 mt-4">
          <div className="w-[100%] md:w-[70%]">
            {dataArticleList ? (
              <>
                {(isAll ? dataArticleList : dataArticleList.slice(0, 5)).map(
                  (item) => {
                    return (
                      <>
                        <ArticleCard
                          categoryArr={item.category_name}
                          slug={item.slug}
                          title={item.title}
                          description={item.description}
                          media={item.media}
                          hasImage={true}
                          type={"big"}
                        />
                      </>
                    );
                  }
                )}
              </>
            ) : dataArticleList && dataArticleList === null ? (
              <p className="text-red-500 text-sx">{errorMessage} </p>
            ) : null}
          </div>

          <div className="w-[30%] hidden md:block mt-5 p-5">
            <div className="bg-slate-100 rounded-md">
              <div className="flex flex-row items-center justify-center gap-3 p-4">
                <a
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100087468924959"
                  rel="noreferrer"
                  className="bg-slate-200 rounded-full w-12 h-12 p-2"
                >
                  <BsFacebook className="w-full h-full text-3xl text-[#3b5998] hover:text-slate-700" />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/olin_indonesia/"
                  rel="noreferrer"
                  className="bg-slate-200 rounded-full w-12 h-12 p-2"
                >
                  <BsInstagram className="w-full h-full text-3xl text-[#E1306C] hover:text-slate-700" />
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC_qBcIbgRIYCR0aIPDD7_Bw"
                  rel="noreferrer"
                  className="bg-slate-200 rounded-full w-12 h-12 p-2"
                >
                  <BsYoutube className="w-full h-full text-3xl text-[#FF0000] hover:text-gray-700" />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/olin-all-in-one-pharmacy-management-system/mycompany/"
                  rel="noreferrer"
                  className="bg-slate-200 rounded-full w-12 h-12 p-2"
                >
                  <FaLinkedinIn className="w-full h-full text-3xl text-[#0072b1] hover:text-gray-700" />
                </a>
              </div>
            </div>
            <div className="flex flex-col mt-5">
              <p className="font-bold text-2xl">Artikel Lainnya</p>
              <hr className="text-black border-1 border-slate-200 rounded-md" />
              {dataArticleList ? (
                <>
                  {" "}
                  {(isAll || dataArticleList.length <= 5
                    ? dataArticleList
                    : dataArticleList.slice(5)
                  ).map((item) => {
                    return (
                      <>
                        <ArticleCard
                          categoryArr={item.category_name}
                          slug={item.slug}
                          title={item.title}
                          description={item.description}
                          media={item.media}
                          hasImage={true}
                          type={"small"}
                        />
                      </>
                    );
                  })}{" "}
                </>
              ) : null}
            </div>
          </div>
        </div>
        {dataArticleList && !isAll ? (
          <div className="items-center justify-center flex">
            <button
              className="border border-slate-200 px-24 border-2 py-3 mb-3 mt-10 hover:bg-slate-100"
              onClick={() => setIsAll(true)}
            >
              Load more
            </button>
          </div>
        ) : null}
      </div>

      <div className="flex flex-col block md:hidden items-center justify-center p-3">
        {dataArticleList ? (
          <>
            {dataArticleList.map((item) => {
              return (
                <div className="flex flex-row  border-2 border-slate-200 my-2 items-center rounded-md">
                  <div className="flex flex-col p-3">
                    <div className="h-full w-full flex items-center">
                      <img
                        src={item.media}
                        alt=""
                        className="w-full h-full object-contain rounded-lg p-3"
                      />
                    </div>
                    <div className="flex flex-row py-2 gap-3 items-center flex-wrap">
                      {item.category_name.slice(0, 2).map((cat) => {
                        return (
                          <p className="px-3 rounded-2xl bg-gray-300 p-2 text-sm">
                            {cat}
                          </p>
                        );
                      })}
                    </div>

                    <p className="font-bold text-md mt-2">
                      <Link to={`/blog-detail/${item.slug}`}>{item.title}</Link>
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: stripHtml(item.description),
                      }}
                      className="text-sm my-2"
                    ></div>
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Articles;
